import {CommercialPageType} from "@/Atoms/Commercial";
import {environment} from "@/helpers/environment";


const initKaching = async (pageUrl: string, pageType: CommercialPageType, adUnit: string) => {

  const kaChingEnabled = environment.getBoolean('VITE_USE_KACHING', true)

  if (!kaChingEnabled) {

    console.warn('KaChing is disabled')
    return;
  }

  const kaChingDebug = environment.getBoolean('VITE_KACHING_DEBUG', false)
  // console.log('page type:', pageType)

  // Mediahuis Advertisement tool (KaChing)
  const kc = new window.KaChing();

  // slots.forEach(slot => kc.defineSlot(slot.name, slot.value))
  let topSlots = 0, bottomSlots = 0;


  document.querySelectorAll('[data-ad-type]').forEach(function (slot) {

    const htmlSlot = slot as HTMLElement
    const {adType, adTop} = htmlSlot.dataset
    const isTop = !!adTop;
    // const slotId = (adId) ? adId : isTop ? `t${topSlots++}` : `b${bottomSlots++}`;
    const slotId = isTop ? `t${++topSlots}` : `b${++bottomSlots}`;

    const id = `ad_${adType}-${slotId}`

    htmlSlot.setAttribute('id', id)
    // Debug purpose:
    // htmlSlot.innerHTML = id;
    console.log(`Add slot ${adType} with id ${slotId}`)
    kc.defineSlot(adType, slotId)




  });

  const slots = topSlots + bottomSlots;

  const config = {
    adunit: kaChingDebug ? `mensenlinq/preview` : `mensenlinq/${adUnit}`,
    platform: 'WWW',
    targeting: {
      page_type: pageType, // home | sectionoverview | detail
    },
    lazyload: {
      fetchMarginPercent: 25,
      renderMarginPercent: 10,
      mobileScaling: 2.0,
      scatterSlots: true,
    },
  };

  await kc.init(config);
  // @ts-ignore
  window.kcx = kc;

}

const destroyKaching = () => {
// connection.disconnect();
  // @ts-ignore
  // console.log('reset', window.kcx)
  // @ts-ignore
  if (window.kcx){
    // @ts-ignore
    window.kcx.reset();
  }
}


export default () => {
  return {initKaching, destroyKaching}
}
