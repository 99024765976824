import {HTMLAttributes} from "react";
import styles from './Commercial.module.scss'
import {useApp} from "@/Providers/AppProvider";

export enum CommercialType
{
  MediumRectangle = 'mediumrectangle', // 320x250, 300x250, 320x200, 320x240
  HalfPageSkyscraper = 'halfpageskyscraper', // ?
  Leaderboard = 'leaderboard',
}


export enum CommercialPageType
{
  Home = 'home', // homepage
  Detail = 'detail', // Blog pages, news etc
  ArticleList = 'detail',// <-- is this right?
  ObituaryDetail = 'articlePage', // Personal obituary detail
  ObituaryIndex = 'sectionoverview', // Obituary listview/search
}






interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  type: CommercialType,
  top?: boolean, // is this a top or a bottom advertisement
  children?: any,
  className?: any,
}

/**
 * Show an KaChing commercial. useKaChing() collects these elements and applies ID and KaChing initialisation
 * @param type
 * @param top
 * @param children
 * @param className
 * @param props
 * @constructor
 */
export default function Commercial({type, top, children, className, ...props}: WrapperProps){

  const {useLegacyWidget} =  useApp();
  if (useLegacyWidget){
    return <></>
  }
  const typeString = type.valueOf();
  return <div
    {...props}
    className={`${className ?? ''} ${styles.commercial}`}
    data-ad-type={typeString}
    data-ad-top={top}
  ></div>
}
