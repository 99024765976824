import Head from "@/Molecules/Inertia/Head";
import Providers from "@/providers";
import {usePage} from "@inertiajs/react";
import React, {useEffect, useState} from "react";
import StorybookWindow from "@/types/StorybookWindow";
import {CommercialPageType} from "@/Atoms/Commercial";
import useKaChing from "@/helpers/useKaChing";
import UpButton from "@/Molecules/UpButton/UpButton";
import {MetaTag} from "@/types/MetaTab";


export default ({children, title, fullTitle, metaTags, pageType, adUnit}: {
  children: any,
  title?: string,
  fullTitle?: string,
  metaTags?: MetaTag[]
  pageType?: CommercialPageType,
  adUnit?: string,
}) => {

  const win = window as StorybookWindow

  const useSafePage = (win.IS_STORYBOOK)
    ? {url: 'dummy-url', component: null}
    : usePage();

  const {url, component} = useSafePage;
  const {initKaching, destroyKaching} = useKaChing();

  useEffect(() => {

    if (!win.IS_STORYBOOK) {
      // Load page advertisements
      const pageTypeValue = pageType || CommercialPageType.Detail;
      if (adUnit) {
        setTimeout( () => {
          initKaching(url, pageTypeValue, adUnit)
        }, 1)// render a frame later to allow react based ads to be detected
      }
    }
    return () => {
      destroyKaching();
    };


  }, []);





  if (fullTitle) {
    title = fullTitle
  } else {
    const appName = import.meta.env.VITE_APP_NAME || 'Laravel';
    title = `${title} - ${appName}`
  }

  return <>
    <Head>
      {title && <title>{title}</title>}
      {metaTags && metaTags.map(({name, content}, index) => <meta key={index} name={name} content={content}/>)}
    </Head>
    {/*<div>Navigation</div>*/}
    {children}
    <UpButton/>
    {/*<div>Footer</div>*/}
  </>
}
